import DeliveryEstimateContext, {
  DeliveryEstimateContextInterface,
} from "./context";
import { ReactNode, useState } from "react";

import algoliasearch from "algoliasearch";
import config from "../../../config.json";
import moment from "moment";
import vinoFetch from "../../utils/vinoFetch";

const appID = process.env.GATSBY_ALGOLIA_APP_ID;
const appKEY = process.env.GATSBY_ALGOLIA_SEARCH_KEY;

// import useSWR from "swr";

const DeliveryEstimateProvider = ({ children }: { children: ReactNode }) => {
  const algoliaClient = algoliasearch(appID, appKEY);
  const algoliaIndex = algoliaClient.initIndex(
    config[process.env.GATSBY_VM_MARKET].ALGOLIA_INDEXES.DELIVERY_ESTIMATES
  );
  const [error, setError] = useState<string>();
  const [postcode, updatePostCode] = useState<string>();
  const [searching, setSearching] = useState<boolean>(false);
  const [deliveryEstimate, setDeliveryEstimate] = useState<string>(undefined);
  const [upperDeliveryEstimate, setUpperDeliveryEstimate] = useState<string>(
    undefined
  );

  // const headers = {
  //   Accept: "application/json",
  //   "Content-Type": "application/json",
  // };

  const checkDeliveryEstimate = async (postcode: string) => {
    updatePostCode(postcode);
    // Decide if you're going to use the netlify function or the algolia index
    if (postcode) {
      setSearching(true);
      if (
        postcode &&
        (postcode.startsWith("08") ||
          postcode.startsWith("8") ||
          postcode.startsWith("6") ||
          postcode.startsWith("5"))
      ) {
        return await algoliaIndex
          .search("", {
            filters: `objectID:${postcode}`,
          })
          .then(({ hits }) => {
            if (hits && hits.length > 0) {
              const filteredResult = hits.find(
                (item) => item.objectID === postcode
              );

              if (filteredResult) {
                const { submittedToDeliveredDays } = filteredResult;
                const result = moment(new Date())
                  .add(submittedToDeliveredDays, "days")
                  .format("dddd, DD MMMM YYYY");

                setError(undefined);
                setDeliveryEstimate(result);
              } else {
                setDeliveryEstimate(undefined);
                setError("No result");
              }
            } else {
              setDeliveryEstimate(undefined);
              setError("No result");
            }
            setSearching(false);
          })
          .catch((err) => {
            setSearching(false);
            setDeliveryEstimate(undefined);
            setError(err);
          });
      } else {
        // Using OLD Delivery status endpoint
        return await vinoFetch
          .get(
            `https://delivery-status.vinomofo.io/.netlify/functions/au-delivery-estimate?to=${postcode}`
          )
          .then((res) => {
            if (res.data && res.data.estimated_delivery) {
              const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              };
              setError(undefined);
              setDeliveryEstimate(
                new Date(res.data.estimated_delivery.date).toLocaleDateString(
                  "en-AU",
                  options
                )
              );
              const upperDate = new Date(res.data.estimated_delivery.date);
              switch (postcode.slice(0, 1)) {
                case "3":
                  upperDate.setDate(upperDate.getDate() + 1);
                  break;
                default:
                  upperDate.setDate(upperDate.getDate() + 2);
              }
              setUpperDeliveryEstimate(
                new Date(upperDate).toLocaleDateString("en-AU", options)
              );
              setSearching(false);
            } else {
              setSearching(false);
              setDeliveryEstimate(undefined);
              setError(res.data.error);
            }
          })
          .catch((error) => {
            setSearching(false);
            setDeliveryEstimate(undefined);
            setError(error);
          });
      }
    }
  };

  const ctx: DeliveryEstimateContextInterface = {
    error,
    postcode,
    searching,
    deliveryEstimate,
    upperDeliveryEstimate,

    setPostcode: checkDeliveryEstimate,
    resetDeliveryEstimate: () => {
      setDeliveryEstimate(undefined);
      setUpperDeliveryEstimate(undefined);
    },
  };

  return (
    <DeliveryEstimateContext.Provider value={ctx}>
      {children}
    </DeliveryEstimateContext.Provider>
  );
};

export default DeliveryEstimateProvider;
